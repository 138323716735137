
.partner-section {
  background-image: url(../../../assets/landpage/background.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.partner-title {
  padding: 20px 0;
  text-align: center;
  font-size: 50px;
}

.partner-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.partner-item {
  width: fit-content;
  text-align: center;
  margin: 20px 20px;
  cursor: pointer;
}

.partner-imagens {
  background-color: white;
  border: 2px solid white;
  border-radius: 5px;
}

.partner-name {
  font-size: 25px;
}