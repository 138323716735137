.recover-password-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.recover-password-screen main {
  text-align: center;
  max-width: 600px;
  width: 100%;
  padding: 1.25rem;
}

.recover-password-screen main p {
  color: var(--font-secondary);
  text-align: left;
}

.recover-password-screen main > * {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.recover-password-screen main form > button {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
