main.notifications-screen {
  padding: 1rem 2.5rem;
}

main.notifications-screen h1 {
  text-align: center;
}

/* main.notifications-screen .notifications-content {

} */

main.notifications-screen .notification-item {
  padding: 1rem 0;
  border-bottom: 1px solid var(--line-details);
  position: relative;
}

main.notifications-screen .notification-item > p {
  font-size: 0.875rem;
  color: var(--font-secondary);
  margin-top: 1rem;
}

main.notifications-screen .notification-item > p.toggle-error {
  position: absolute;
  padding: 0.5rem 0.75rem;
  border-radius: 3px;
  color: #d8000c;
  background-color: #ffbaba;
  right: 0;
  bottom: 8px;
  z-index: 1;
}

@media (min-width: 1015px) {
  /* main.notifications-screen {

  } */

  main.notifications-screen h1 {
    text-align: left;
  }

  main.notifications-screen .notifications-content {
    display: grid;
    grid-template-columns: repeat(1, 1fr 1fr);
    column-gap: 2.5rem;
  }
}
