.mainPrivacyModal {
  background: #464648 !important;
}

.terms-screen {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
}

.terms-screen main {
  max-width: 820px;
  width: 100%;
  padding: 1.25rem;
  background-color: var(--bg-secondary);
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.terms-screen h1, .terms-screen h2, .terms-screen h3 {
  text-align: center;
}


.terms-screen h1 {
  font-size: 1.35rem;
}

.terms-screen h2 {
  font-size: 1.175rem;
}

.terms-screen p {
  font-size: 1rem;
}

.terms-screen .terms-header {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-bottom: 0;
}

.terms-header .logo-title {
  font-size: 1rem;
  font-weight: bold;
}

.terms-header .logo-title::before {
  content: "";
  background-image: url(../../assets/landpage/escudo.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: block;
  margin: auto;
  margin-bottom: .5rem;
  width: 50px;
  height: 50px;
}

.terms-screen main form > button {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.terms-screen main form > button:last-child {
  margin-bottom: 0;
}

.terms-content {
  max-height: 60vh;
  overflow: auto;
  padding: .75em 2em;
}

.terms-screen main .checkbox-terms {
  margin: 1.2em auto;
  width: fit-content;
}

.terms-screen main .checkbox-terms span::before {
  background-color: var(--bg-primary);
}

@media (max-width: 500px) {
  .terms-screen main {
    max-height: 100vh;
    height: 100vh;
  }
  .terms-content {
    max-height: 90vh;
    padding: 0.75em 1em;
  }
}

@media (min-width: 1015px) {
  .terms-screen main > * {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .terms-screen .terms-header {
    display: grid;
    grid-template-columns: 1fr 1.5fr 1fr;
  }
  .terms-header .logo-title {
    order: 1;
  }
  .terms-header h1 {
    order: 2;
  }
}
