main.faq-screen {
  padding: 2rem 1.5rem;
}

main.faq-screen h1 {
  text-align: center;
}

main.faq-screen h2 {
  font-size: 1rem;
  margin-top: 0;
}

main.faq-screen .faq-item {
  padding: 1rem;
  border: 1px solid var(--line-details);
  margin: 0.5rem 0;
}

main.faq-screen .faq-item p {
  font-size: 0.875rem;
  color: var(--font-secondary);
}

main.faq-screen .error-msg {
  margin-bottom: 1rem;
}

main.faq-screen .pagination-btn {
  text-transform: none;
  padding: .5rem;
}

@media (min-width: 800px) {
  .faq-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
  }
}

@media (min-width: 1015px) {
  main.faq-screen {
    padding: 1rem 1rem;
  }
}

@media (min-width: 1660px) {
  .faq-content {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
