/* GLOBAL STYLES */

:root {
  --bg-primary: #272728;
  --bg-secondary: #464648;
  --bg-tertiary: rgba(70, 70, 72, 0.5);
  --bg-quaternary: #3E3E3E;
  --line-details: #707070;
  --font-primary: #F0F1F1;
  --font-secondary: #BCBCBC;
  --action-primary: #8BC43F;
  --action-secondary: #D92F5E;
  --action-tertiary: #019AD9;
  --action-quaternary: #F8A51B;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  background-color: var(--bg-primary);
  color: var(--font-primary);
}

h1, h2, h3 {
  text-transform: uppercase;
}

h1 {
  font-size: 1.5em;
}

h2 {
  font-size: 1.175em;
  font-weight: 500;
}

a {
  color: var(--font-primary);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
