
aside.balance-screen {
  display: block;
}

main.balance-screen {
  padding: 1rem;
  height: initial;
}

main.balance-screen section {
  margin-bottom: 2rem;
}

main.balance-screen section h2 {
  margin-top: 0;
  font-size: 1.175rem;
  font-weight: bold;
  color: var(--font-secondary);
}

main.balance-screen section button {
  margin-top: 1rem;
}

main.balance-screen #checkout-btn {
  background-color: var(--action-tertiary);
}

main.balance-screen section .transaction-item {
  color: var(--font-secondary);
  padding: 0.75rem 0;
  border-bottom: 1px solid var(--line-details);
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
}

.transaction-item span.red {
  color: var(--action-secondary);
}

.transaction-item span.green {
  color: var(--action-primary);
}

.transaction-item span.blue {
  color: var(--action-tertiary);
}

/* aside.balance-screen  {
  display: block;
} */

aside.balance-screen > .user-info {
  display: none;
}

aside.balance-screen .my-balance h1 {
  font-size: 1.2rem;
  margin-top: 2em;
  text-align: center;
}

aside.balance-screen .cash-resume {
  background-color: var(--bg-secondary);
  padding: 1rem;
  margin-bottom: 1.1rem;
  border-right: 1rem solid var(--action-tertiary);
  color: var(--font-secondary);
}

aside.balance-screen .cash-resume.withdraw {
  border-right: 1rem solid var(--action-primary);
}

aside.balance-screen .cash-resume h2 {
  margin-top: 0;
  font-size: 1rem;
  font-weight: bold;
}

aside.balance-screen .cash-resume .available-label {
  font-size: 1rem;
}

aside.balance-screen .cash-resume .available-value {
  font-size: 1.4rem;
  font-weight: bold;
  margin: .5rem 1rem;
}

aside.balance-screen .convert-button {
  color: white;
  text-align: center;
  font-size: 0.9rem;
  padding: 8px 0 8px 0;
  margin-bottom: 20px;
  /* background: linear-gradient(180deg, var(--action-tertiary) 50% , var(--action-primary) 50%); */
  background-color: var(--action-primary);
  cursor: pointer;
}

@media (min-width: 1015px) {
  main.balance-screen {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 3rem;
    padding: 1.5rem 3rem;
  }

  aside.balance-screen > .user-info {
    display: block;
  }
}

@media (max-width: 1014px) {
  aside.balance-screen {
    padding: 1rem;
  }
}

.chart {
  position: relative;
}

.chart > svg {
  position: absolute;
}
