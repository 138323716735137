.app-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;

  --nav-height: 140px;
}

.app-container > aside {
  display: none;
  padding: 3.25em 2em;
  /* background-color: tomato; */
}

.app-container > aside .user-name {
  text-align: center;
  font-weight: 900;
  margin: 1em 0;
}

.app-container > aside .page-helper-text {
  text-align: center;
  margin: 2.175em;
}

.app-container > main {
  /* background-color: var(--bg-secondary); only on larger screen sizes */
  height: calc(100vh - var(--nav-height));
  overflow: auto;
}

.app-container > main h1 {
  font-size: 1.25rem;
}

.app-container > nav {
  /* background-color: rgba(100,100,100,0.1); */
  height: var(--nav-height);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
  padding: 0 15px;
}

.app-container > nav > a {
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 15px;
}

.app-container > nav > a:last-child {
  margin-right: 0;
}

.user-ranking {
  color: var(--font-secondary);
}

.user-ranking h3 {
  font-size: 1rem;
}

/* need to increase breakpoint */
@media (min-width: 1015px) {

  .app-container {
    --nav-height: 150px;
    display: grid;
    /* grid-template-columns: 1fr 4fr 2fr 12fr 4fr; */
    grid-template-columns: 4.5fr 1.5fr 10fr 3fr;
    grid-template-rows: var(--nav-height) auto 2fr;
    grid-template-areas: 
      "aside . nav ."
      "aside . main ."
      "aside . . .";
  }
  
  .app-container > aside {
    display: block;
    grid-area: aside;
    /* justify-self: center;
    align-self: start; */
  }
  
  .app-container > main {
    background-color: var(--bg-secondary);
    min-height: calc(100vh * 14 / 17 - var(--nav-height));
    grid-area: main;
    overflow: unset;
    height: auto;
  }
  
  .app-container > nav {
    grid-area: nav;
    align-self: center;
    justify-self: stretch;
    /* justify-content: space-between; */
    padding: 0;
    overflow: initial;
  }

}

@media (min-width: 1700px) {
  .app-container {
    --nav-height: 190px;
  }
}

@media (min-width: 2100px) {
  .app-container {
    --nav-height: 230px;
  }
}
