.app-container > aside.user-menu {
  z-index: 2;
  background-color: var(--bg-secondary);
  display: block;
  position: absolute;
  /* max-width: 100vw; */
  width: 100vw;
  height: 100vh;
  transform: translateX(-100%);
  transition: transform 0.4s;

  padding: 3.25em 2em;
  color: var(--font-secondary);
}

.app-container > aside.user-menu.open {
  transform: translateX(0);
}

aside.user-menu h2 {
  font-size: 1.25rem;
  color: var(--font-primary);
}

h2.nav-title {
  text-align: center;
  margin: 1.25em auto;
}

.user-links-nav {
  display: flex;
  flex-direction: column;
}

.user-link {
  padding: 0.75rem 0;
  font-size: 1rem;
  color: var(--font-secondary);

  border-bottom: 1px solid var(--line-details);
}

.user-link:hover {
  text-decoration: none;
}

.about-title {
  margin-top: 2em;
}

.about-content {
  display: flex;
  justify-content: space-between;
}

.menu-button {
  position: absolute;
  margin: 1rem;
  z-index: 2;
}

.avatar-label > input {
  display: none;
}

.avatar-label > .avatar::after {
  content: "";
  background-image: url(../../assets/icons/edit.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: block;
  position: absolute;
  bottom: 0%;
  right: 0%;
  transform: translate(-25%,25%);
  width: 1.5rem;
  height: 1.5rem;
}

@media (min-width: 1015px) {

  .app-container > aside.user-menu {
    position: initial;
    transform: translateX(0);
    width: initial;
  }

  .menu-button {
    display: none;
  }
}
