.mainPrivacyModal {
  background: #464648 !important;
}

.mainPrivacyModal div {
  text-align: left;
}

.mainPrivacyModal p {
  line-height: 1.2em;
  font-size: 1rem;
}