/* APP SECTION */

#app-ad {
  background-color: var(--font-primary);
  color: var(--bg-primary);
}

#app-ad p {
  line-height: 1.5em;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}

#app-ad p span {
  color: var(--action-secondary);
  font-weight: 600;
}

.landing-section {
  padding: 90px 1rem;
}

.download-links {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 800px) {
  #app-ad {
    display: flex;
    justify-content: center;
    padding: 90px 15%;
  }

  #app-ad > div {
    flex: 1;
  }

  #app-ad h2 {
    text-align: left;
  }
}

/* FOOTER */

.landing-footer {
  font-size: 1rem;
  border-top: 3px solid #C0BFBF;
}

.landing-footer > div {
  text-align: center;
  margin: 2rem 0;
}

.landing-footer .logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.landing-footer h2 {
  margin-bottom: 0;
  margin-top: .5rem;
  font-size: 1.5rem;
}

.landing-footer .logo {
  height: 96px;
  width: 96px;
  object-fit: contain;
  margin: auto;
  display: block;
}

.landing-footer h3 {
  margin: 1rem 0;
  text-transform: none;
}

.landing-footer .social {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: .5rem;
}

.landing-footer .social .social-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.landing-footer .social li:not(:last-child) {
  margin-right: 1rem;
}

.landing-footer .terms ul li:not(:last-child) {
  margin-bottom: 0.5rem;
}

.clickLi {
  cursor: pointer;
}

.landing-footer .rights {
  /* background-color: var(--bg-secondary); */
  font-size: 0.875rem;
  padding: 0.5rem;
  margin-bottom: 20px;
  margin-top: 5px !important;
  grid-column-end: span 3;
  text-align: center;
}

.devari-link {
  cursor: pointer;
  font-size: 12px;
  text-align: center;
  margin-bottom: 10px;
}

.devari-link > span {
  color: #22BEE7;
}

@media (min-width: 800px) {
  .landing-footer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

}

@media (min-width: 1000px) {
  .tournaments-container {
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 1000px;
    margin: auto;
  }
}

@media (min-width: 1280px) {
  .landing-screen .landing-section {
    padding: 120px 15%;
  }
}

@media (min-width: 1900px) {
  .landing-screen .landing-section {
    padding: 120px 20%;
  }
  #app-ad {
    padding: 90px 20%;
  }
}
