@import url('https://fonts.googleapis.com/css?family=Passion+One');

.landing-screen main {
  padding: 120px 2rem;
  background-image: url(../../../assets/landpage/background.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.landing-screen .row1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.landing-screen .title {
  color: red;
  font-size: 160px;
  font-family: "Passion One", Helvetica, sans-serif !important;
  text-align: center;
  font-weight: 900;
  width: 400px;
  line-height: 110px;
  border-bottom: 2px solid white;
}

.landing-screen .subtitle {
  text-align: center;
  font-size: 30px;
  font-weight: 900;
}

.landing-screen .mascote {
  width: 350px;
}

.landing-section .row2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.landing-section .row2 img {
  width: 300px;
}

.landing-section .row2-title {
  font-size: 40px;
  font-weight: 100;
}
.landing-section .row2-subtitle {
  font-size: 30px;
  font-weight: 100;
}
.landing-section .row2 .sub-button {
  background-color: rgb(225, 93, 58);
  padding: 20px;
  border-radius: 2px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  font-size: 30px;

  animation: shake 3s cubic-bezier(0, 0, 0.58, 1) both infinite;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%,
  90% {
    transform: rotate(2deg);
    /* transform: translate3d(-1px, 0, 0); */
  }
  20%,
  80% {
    transform: rotate(0deg);
    /* transform: translate3d(2px, 0, 0); */
  }
  30%,
  50%,
  70% {
    transform: rotate(-2deg);
    /* transform: translate3d(-4px, 0, 0); */
  }
  40%,
  60% {
    transform: rotate(0deg);
    /* transform: translate3d(4px, 0, 0); */
  }
}

.landing-section .row3 {
  margin-top: 40px;
}
.landing-section .row3-title {
  text-align: center;
  font-size: 30px;
}
.landing-section .row3-line {
  margin: 0  auto 30px auto;
  width: 80%;
  border-bottom: 2px solid white;
}
.landing-section .row3 .infor-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.landing-section .row3 .infor-texts {
  max-width: 50%;
}
.landing-section .row3 .infor-texts p {
  font-size: 25px;
}

@media (max-width: 881px) {
  .landing-section .row3 .infor-container {
    justify-content: center;
  }
  .landing-section .row3 .infor-texts {
    max-width: initial;
  }
}

.landing-screen h1 {
  text-align: left;
  line-height: 1.5em;
  text-transform: none;
  font-size: 40px;
  max-width: 660px;
}

.landing-screen main p {
  line-height: 1.5em;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}

.landing-screen h1 > span {
  color: var(--action-primary);
}

@media (max-width: 730px) {
  .landing-screen .row1 {
    justify-content: center;
  }
  .landing-screen .title {
    margin-top: 50px;
  }
}