main.transactions-screen {
  padding: 1rem 0.75rem;
}

main.transactions-screen h1 {
  text-align: center;
}

main.transactions-screen .pagination-btn {
  margin-top: 1rem;
}

.transactions-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 2rem;
}

.transactions-table thead th {
  border-bottom: 1px solid var(--line-details);
  padding: .75rem .5rem;
  /* text-align: left; */
}

/* .transactions-table tbody::before {
  height: 1rem;
  display: table-row;
  content: '';
} */

.transactions-table td {
  font-size: 1rem;
  padding: .75rem .5rem;
  text-align: center;
  border-bottom: 1px solid var(--line-details);
  color: var(--font-secondary);
}

.transactions-table td.description {
  text-align: left;
}

.transactions-table td.add {
  color: var(--action-primary);
}

.transactions-table td.remove {
  color: var(--action-secondary);
}

.transactions-table th:first-child, .transactions-table td:first-child {
  text-align: left;
}

.transactions-table th:last-child, .transactions-table td:last-child {
  text-align: right;
}

@media (min-width: 800px) {
  main.transactions-screen {
    padding: 1rem 2rem;
  }
}
