.nav-item-container {
  background-color: var(--bg-tertiary);
  display: inline-block;
  width: 100px;
  height: 100px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: stretch;
  font-size: 1rem;
  padding: 0.875rem 0;
  /* width: 27%;
  padding-top: 27%;
  padding-bottom: 0; */
  position: relative;
}

.nav-item-container:hover {
  text-decoration: none;
}

@media (max-width: 500px) {
  .nav-item-container {
    width: 27%;
    height: unset;
    padding: 0;
    padding-top: 27%;
  }
}

@media (min-width: 1015px) {
  .nav-item-container {
    width: 100px;
    height: 100px;
  }
}

@media (min-width: 1700px) {
  .nav-item-container {
    width: 120px;
    height: 120px;
    /* padding: 1.4rem 0; */
  }
}

@media (min-width: 2100px) {
  .nav-item-container {
    width: 140px;
    height: 140px;
    /* padding: 1.6rem 0; */
  }
  .nav-item-container > span {
    top: 22.5%;
    bottom: 22.5%;
  }
}
