main.ranking-screen {
  padding: 1rem;
  min-height: initial;
}

main.ranking-screen h2 {
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 0;
}

main.ranking-screen section:not(:last-child) {
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--line-details);
}

aside.ranking-screen .my-ranking {
  background-color: var(--bg-secondary);
  padding: 1rem;
  text-align: center;
}

aside.ranking-screen .my-ranking h3 {
  font-size: 1.2rem;
  text-transform: initial;
  margin-bottom: 0;
}

aside.ranking-screen .my-ranking p {
  margin-top: 0.5rem;
  margin-bottom: 3rem;
  font-size: 1rem;
}

aside.ranking-screen .my-ranking p:last-child {
  margin-bottom: 1rem;
}

main.ranking-screen .ranking-content {
  width: 100%;
  margin-top: 2rem;
}

@media (min-width: 1015px) {
  main.ranking-screen .ranking-content {
    display: flex;
  }

  main.ranking-screen .ranking-content > section {
    flex: 1;
    padding: 0 1rem;
    padding-left: 3rem;
  }

  main.ranking-screen .ranking-content > section:not(:last-child) {
    margin-bottom: 0;
    padding-bottom: 0;
    padding-right: 3rem;
    padding-left: 1rem;
    border-bottom: none;
    position: relative;
    /* border-right: 1px solid var(--line-details); */
  }
  main.ranking-screen .ranking-content > section:not(:last-child)::after {
    content: "";
    display: block;
    width: 1px;
    height: 80%;
    background-color: var(--line-details);
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
  .user-ranking {
    display: none;;
  }
}
