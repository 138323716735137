.section-bets-infor {
  width: 100%;
  padding-top: 30px;
  background-image: linear-gradient( 109.6deg,  rgba(33,103,43,1) 11.3%, rgba(117,162,61,1) 91.1% );
  text-align: center;
}

.bets-title {
  font-size: 40px;
}

.bets-line {
  border-bottom: 2px solid white;
  margin: 20px auto;
  width: 80%;
}

.bets-subtitle {
  font-size: 25px;
  padding: 10px 20px 10px 20px;
}

.bets-image {
  width: 80vw;
}

.bets-list-container {
  width: fit-content;
  margin: 0 auto;
  padding: 0 20px 20px 20px;
}

.bets-list-container li {
  text-align: left;
  padding: 10px 0;
}

.bets-money {
  margin-right: 20px;
}

.bets-list-text {
  font-size: 25px;
}