.login-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

@media (min-width: 1015px) {
  .login-screen {
    background: linear-gradient(rgba(39, 39, 39, 0.5), rgba(36, 36, 36, 0.5)), url(../../assets/landpage/mascote.png);
    /* background-image: url(../../assets/landpage/mascote.png); */
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.login-screen main {
  background: transparent;
  text-align: center;
  max-width: 600px;
  width: 100%;
  padding: 1.25rem;
}

.login-screen main > * {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.login-screen main form > button {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.login-screen main form > a {
  font-size: 0.875em;
}

.login-screen .app-logo {
  width: 140px;
  height: 140px;
  object-fit: contain;
}

a.recover-password-link {
  color: #019AD9;
  text-align: left;
  margin-top: 0.5em;
  display: block;
}
