
#tournaments {
  background-image: url(../../../assets/landpage/background.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  padding: 90px 1rem;
}

.tour-row-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.tour-joke-image {
  width: 250px;
  object-fit: contain;
}

.tour-infor1-container {
  width: 35%;
}

.tour-infor1-title {
  font-size: 35px;
  text-align: center;
  border-bottom: 2px solid white;
  padding-bottom: 8px;
  margin-bottom: 16px;
}

.tour-infor-text {
  font-size: 22px;
  text-align: center;
  margin-bottom: 16px;
  line-height: 1.4;
}

.tour-infor2-title {
  font-size: 35px;
  text-align: center;
  padding-bottom: 8px;
  margin-bottom: 16px;
}

.tour-line-title {
  border-bottom: 2px solid white;
  width: 70%;
  margin: 0 auto 20px auto;
}

.tour-center-container {
  width: 80%;
  margin: 20px auto;
}

.tour-infor-row-main {
  text-align: center;
  font-size: 28px;
}
.tour-infor-row-text {
  text-align: center;
  font-size: 22px;
  margin-top: 10px;
}

.tour-title-text {
  font-size: 2.25rem;
  font-weight: 700;
  text-align: center;
  text-transform: none;
  margin: 30px 0;
}

.tournaments-container {
  display: grid;
}

.tournaments-container > li {
  background-image: linear-gradient( 109.6deg,  rgba(33,103,43,1) 11.3%, rgba(117,162,61,1) 91.1% );
  width: 280px;
  margin: 1rem auto;
}

.tournaments-container h3 {
  font-size: 1.2rem;
  text-align: center;
  /* margin: 0.25rem; */
}

.tournament-card {
  padding: 1.2rem;
  border-radius: 2px;
  /* box-shadow: 0 2px 10px 0px rgba(0,0,0,0.5); */
}

.mid-level .tournament-card {
  background-color: var(--action-quaternary);
}

.low-level .tournament-card {
  background-color: var(--action-tertiary);
}

.tournament-card .price {
  margin-bottom: 1rem;
  font-size: 1.35rem;
  font-weight: 500;
  text-align: center;
}

.tournament-info > * {
  margin-bottom: .5rem;
}

.tournament-card .btn-link {
  display: block;
  margin: auto;
  width: fit-content;
  margin-top: 1rem;
  text-transform: uppercase;
}


@media (max-width: 800px) {
  .tour-row-container {
    flex-direction: column;
    align-items: center;
  }
  .tour-infor1-container {
    width: 90%;
    margin-top: 20px
  }
}