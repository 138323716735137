.register-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

@media (min-width: 1015px) {
  .register-screen {
    background: linear-gradient(rgba(39, 39, 39, 0.5), rgba(36, 36, 36, 0.5)), url(../../assets/landpage/mascote.png);
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
  }
}


.register-screen main {
  text-align: center;
  max-width: 600px;
  width: 100%;
  padding: 1.25rem;
}

.register-screen main > * {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.register-screen main form > button,  .register-screen main form > a{
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.register-screen main form > a.terms-link {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

a.terms-link:hover {
  text-decoration: none;
}

.register-screen main form > button:last-child {
  margin-bottom: 0;
}
