main.tournaments-screen {
  padding: 1rem;
}

main.tournaments-screen h1, main.tournaments-screen h2 {
  text-align: center;
  font-size: 1.175rem;
  font-weight: bold;
}

.tournaments-content {
  /* height: 80%; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.choice-btn {
  display: block;
  width: 220px;
  min-height: 70px;
  padding: 0.5rem 0;
  box-shadow: 0px 3px 6px #00000080;
  margin: 2rem auto;
  text-transform: uppercase;
  font-size: 1.175rem;
  font-weight: 800;
  cursor: pointer;
  /* padding: 1rem; */
}

.choice-btn > .light {
  font-weight: 400;
}

.choice-btn.high {
  background-color: var(--action-secondary);
}

.choice-btn.mid {
  background-color: var(--action-quaternary);
}

.choice-btn.low {
  background-color: var(--action-tertiary);
}

.choice-btn.selected {
  background-color: transparent;
  box-shadow: none;
  border: 3px solid var(--font-primary);
}

.choice-btn.disabled {
  opacity: 0.65;
}

.confirmation-container .confirmation-title {
  display: none;
}

.tournament-corfirmation {
  display: flex;
  align-items: stretch;
  width: 100%;
  flex-direction: column;
}

.tournament-info li {
  text-align: center;
}

.tournament-corfirmation > * {
  flex: 1;
}

main.tournaments-screen .button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

main.tournaments-screen .button-container > button {
  min-width: 120px;
  margin: 1rem 0.5rem;
}

main.tournaments-screen .button-container > button.light-border {
  border-color: var(--font-primary);
}

@media (min-width: 720px) {
  .confirmation-container .confirmation-title {
    display: block;
    text-align: center;
    max-width: 12em;
    margin: 1em auto 2em auto;
  }
  .confirmation-container .confirmation-title::before {
    content: "";
    display: block;
    margin: 1em auto;
    width: 70px;
    height: 70px;
    background-image: url(../../assets/icons/torneios.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .tournaments-content {
    flex-direction: row;
    align-items: center;
    position: relative;
  }

  main.tournaments-screen .tournaments-content::after {
    content: "";
    display: block;
    width: 1px;
    height: 90%;
    background-color: var(--line-details);
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
  }
  .tournament-corfirmation {
    flex-direction: row;
    align-items: flex-start;
  }
}

/* @media (min-width: 2000px) {
  
} */