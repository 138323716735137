.landing-header {
  background-color: #000;
  /* position: fixed; */
  width: 100%;
  /* height: 62px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 1rem;
  box-shadow: 0 2px 5px 0px rgba(0,0,0,0.35);
  border-bottom: 4px solid var(--font-secondary);
}

.landing-header .logo {
  height: 48px;
  width: 48px;
  object-fit: contain;
  /* position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin: 8px 0; */
}

.landing-header .header-title {
  font-size: 25px;
  margin: 0 10px
}

.landing-header .menu-button {
  left: 0;
}

.landing-header nav > a:first-child {
  margin-right: 1rem;
}

.btn-link {
  padding: 0.5rem 1rem;
  display: inline-block;
}

.btn-link:hover {
  text-decoration: none;
}

.btn-primary {
  background-color: var(--action-primary);
  border: 1px solid var(--action-primary);
}

.btn-outline {
  padding: 0.5rem 1rem;
  border: 1px solid white;
}

@media (max-width: 1012px) {
  .landing-header .btn-primary {
    width: 100%;
    text-align: center;
    margin: 5px 0;
  }
  .landing-header .btn-outline {
    width: 100%;
    text-align: center;
    margin: 5px 0;
  }
}

@media (max-width: 433px) {
  .landing-header .header-title { display: none; }
}