main.contact-screen {
  padding: 2rem;
}

main.contact-screen h1 {
  text-align: center;
}

main.contact-screen form label, main.contact-screen form button {
  margin-bottom: 1.5rem;
  display: block;
}

main .feedback-msg {
  margin-bottom: 1rem;
}

@media (min-width: 1015px) {
  main.contact-screen {
    padding: 2rem 2.5rem;
  }

  main.contact-screen h1 {
    margin-top: 0;
    margin-bottom: 1.5rem;
  }

  main.contact-screen form {
    max-width: 900px;
    margin: auto;
  }

  /* main.contact-screen form > div {
    width: 40%;
  } */
}
