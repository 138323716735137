main.bets-screen {
  padding: 1rem;
}

main.bets-screen h1 {
  text-align: center;
}

main.bets-screen h2 {
  font-size: 1rem;
  margin: 0;
}

main.bets-screen .bet-item {
  background-color: var(--bg-secondary);
  box-shadow: 0px 3px 6px #00000080;
  padding: .5rem;

  display: flex;
  align-items: stretch;
  flex-direction: column;

  font-size: 1rem;
  color: var(--font-secondary);

  margin-bottom: 1rem;
}

main.bets-screen .bet-item .bet-content {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

main.bets-screen .bet-item .joker-content {
  margin-top: 0.75rem;
  display: flex;
  justify-content: center;
}

main.bets-screen .bet-content > div {
  flex: 1;
  width: 33%;
  margin-right: 0.5rem;
}

main.bets-screen .bet-content > div:last-child {
  margin-right: 0;
}

.bet-item .team-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  padding: 1rem 0;
}

.bet-item .team-info img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.bet-item .game-date {
  font-size: 0.875rem;
}

.bet-item .game-info {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  text-align: center;
}

.game-info .score-inputs-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--font-primary);
  font-weight: 700;
}

.score-inputs-container .score-input {
  padding: 0.5rem;
  background-color: var(--bg-primary);
  color: var(--font-primary);
  text-align: center;
  border: none;
  width: 2.25rem;
  height: 2.25rem;
}

main.bets-screen .import-btn {
  margin: 1rem 0;
  padding: 0.5rem 0.75rem;
  text-transform: none;
  font-size: 1rem;
  min-width: unset;
  width: fit-content;
  border-color: var(--font-primary);
  box-shadow: 2px 2px 4px #00000080;
}

main.bets-screen .feedback-msg {
  margin-bottom: 1rem;;
}

@media (min-width: 720px) {
  .bets-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
  }
}

@media (min-width: 2000px) {
  .bets-content {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2rem;
  }
}

