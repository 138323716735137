main.user-profile-screen {
  padding: 2rem;
}

main.user-profile-screen h1 {
  text-align: center;
}

main.user-profile-screen form label, main.user-profile-screen form button {
  margin-bottom: 1.5rem;
  display: block;
}

.favorite-info {
  position: relative;
}

main.user-profile-screen .alert-msg {
  margin-bottom: 1.5rem;
}

.favorite-info img {
  position: absolute;
  right: 48px;
  bottom: 10px;
  width: 32px;
  height: 32px;
  z-index: 1;
  object-fit: contain;
}

.profileModalContainer {
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  padding: 1.5rem;
  width: 320px;
  background-color: var(--bg-secondary);
  align-items: center;
}

.profileModalContainer button {
  margin: 10px 0;
}

.react-select-container .css-yk16xz-control, .react-select-container .css-1pahdxg-control {
  background-color: transparent;
  border: none;
  box-shadow: none;
  border-bottom: 1px solid var(--line-details);
  border-radius: 0;
  color: var(--font-primary);
}

.react-select-container .css-1pahdxg-control:hover {
  box-shadow: none;
}

.react-select-container > span {
  background-color: transparent;
}

.react-select-container  .css-1uccc91-singleValue {
  color: var(--font-secondary);
}

.react-select-container  .css-26l3qy-menu {
  /* background-color: var(--font-secondary); */
  color: var(--bg-primary);
}

@media (min-width: 1015px) {
  main.user-profile-screen {
    padding: 2rem 2.5rem;
  }

  main.user-profile-screen h1 {
    text-align: left;
    text-transform: initial;
    margin-top: 0;
    margin-bottom: 1.5rem;
  }

  main.user-profile-screen form {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }

  main.user-profile-screen form > div {
    width: 40%;
  }

  .favorite-info img {
    position: initial;
    width: 100px;
    height: 100px;
    margin: auto;
    margin-bottom: 2rem;
    display: block;
  }

  .favorite-info label {
    text-align: center;
  }

  .favorite-info label > input {
    text-align: center;
    margin-top: 0.75rem;
    padding-bottom: 0.5rem;
  }
}
