
.payment-section {
  background-color: white;
  padding: 0 120px;
}

.payment-title {
  padding: 20px 0;
  text-align: center;
  font-size: 45px;
  color: rgb(14, 126, 0);
}

.payment-row1 {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 20px auto; */
}

.payment-line {
  border-bottom: 2px solid rgb(14, 126, 0);
  margin: 0 auto 20px auto;
  max-width: 80%;
}

.payment-infor-container {
  padding: 0 20px;
}

.payment-infor-container p {
  text-align: center;
  margin-bottom: 20px;
}

.payment-deposito-title {
  font-size: 35px;
  color:rgb(14, 126, 0);
  text-align: center;
}

.payment-text {
  color: #333;
  text-align: center;
}

.payment-image {
  padding: 0 20px;
  width: fit-content;
}


@media (max-width: 925px) {
  .payment-row1 {
    flex-direction: column;
  }
}

@media (max-width: 650px) {
  .payment-section {
    padding: 0 60px;
  }
}